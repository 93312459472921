import React, { useEffect, useMemo, useReducer, useState } from "react";
import { List, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useSettings from "../../hooks/useSettings";
import useSocket from "../../hooks/useSocket";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

const useStyles = makeStyles(theme => ({
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
    },
    ticketsList: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    },
    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px",
    },
    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },
    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },
    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const tickets = action.payload;
        // const newTickets = [];

        tickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
                // newTickets.push(ticket);
            }
        });

        return [...state];
        // return [...state, ...newTickets];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticket.id);

        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }
        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticketId);

        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const TicketsList = (props) => {
    const {
        fixUser,
        isGroup,
        // priority,
        searchParam,
        selectedQueueIds,
        showAll,
        status,
        style,
        tags,
        updateCount,
    } = props;

    const classes = useStyles();

    // State
    const [pageNumber, setPageNumber] = useState(1);
    // const [limitNoQueue, setLimitNoQueue] = useState("disabled");

    // Reducer
    const [ticketsList, dispatch] = useReducer(reducer, []);

    // Context
    const { settings, getSettingValue } = useSettings();
    const { userTenant, userTenantAdminRole } = useTenant();
    const { socket } = useSocket();

    // Set the tickets state
    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [
        dispatch,
        isGroup,
        // limitNoQueue,
        searchParam,
        selectedQueueIds,
        showAll,
        status,
        tags,
    ]);

    // Get tickets using its own context
    const { tickets, hasMore, loading } = useTickets({
        fixUser, // CHECK IF NEEDED
        isGroup,
        // limitNoQueue,
        pageNumber,
        queueIds: JSON.stringify(selectedQueueIds),
        searchParam, // Insert here the filter
        showAll,
        status,
        userTenant,
        tags: JSON.stringify(tags)
    });

    const limitNoQueue = useMemo(() => {
        if (!settings || !userTenant?.role) return;

        // Get settings information
        const restrictToQueues = settings?.length > 0 && getSettingValue("restrictToQueues") === "enabled";

        // If userTenant not an admin role and restriction is set
        if (!userTenantAdminRole && restrictToQueues) {
            // setLimitNoQueue("enabled");
            return true
        }

        // If userTenant admin role or no restriction is set
        if (userTenantAdminRole || !restrictToQueues) {
            // setLimitNoQueue("disabled");
            return false
        }
    }, [getSettingValue, settings, userTenant, userTenantAdminRole])

    useEffect(() => {
        let chats = tickets;
        if (isGroup !== undefined) {
            chats = tickets.filter((t) => t?.isGroup === isGroup);
        }

        const queueIds = userTenant?.queues?.map((q) => q.id);
        const filterQueues = chats.filter((t) => queueIds.indexOf(t.queueId) > -1);
        // const filteredTickets = filterQueues.filter((t) => selectedQueueIds.indexOf(t.queueId) > -1);

        if (userTenant?.role === "user") {
            dispatch({ type: "LOAD_TICKETS", payload: filterQueues });
        } else {
            dispatch({ type: "LOAD_TICKETS", payload: chats });
        }
    }, [isGroup, tickets, status, searchParam, userTenant?.queues, selectedQueueIds, userTenant?.role]);

    // Helper functions
    // Check if should update the ticket
    const shouldUpdateTicket = (ticket) => !searchParam && !tags &&
    (!ticket?.userTenantId || ticket?.userTenantId === userTenant?.id || showAll) &&
    (!ticket?.queueId || selectedQueueIds.indexOf(ticket?.queueId) > -1)




    // Check if ticket is group
    const checkIfGroup = (ticket) => (isGroup !== undefined && ticket?.isGroup === isGroup) || (isGroup === undefined);

    // Check if the message belongs to user queues
    const notBelongsToUserQueues = (ticket) =>
        ticket?.queueId && selectedQueueIds.indexOf(ticket?.queueId) === -1;


    // Handlers
    // Handle Contact Changes
    const handleContactChange = (data) => {
        if (data.action === "update") {
            dispatch({ type: "UPDATE_TICKET_CONTACT", payload: data.contact });
        }
    };
    useSocketListener(`contact`, (data) => handleContactChange(data))

    // Handle Chat Changes
    const handleChatChange = (data) => {
        if (data.action === "create" && shouldUpdateTicket(data.ticket) && checkIfGroup(data.ticket)) {
            dispatch({
                type: "UPDATE_TICKET_UNREAD_MESSAGES",
                payload: data.ticket,
            });
        }
    };
    useSocketListener(`message`, (data) => handleChatChange(data))

    // Special Socket listener function
    useEffect(() => {
        if (!socket || !userTenant || !selectedQueueIds) return;

        // Handle Chats Changes
        const handleChatsChange = (data) => {
            if (data.action === "updateUnread") {
                dispatch({ type: "RESET_UNREAD", payload: data.ticketId });
            }

            if (data.action === "update" && shouldUpdateTicket(data.ticket) && checkIfGroup(data.ticket)) {
                dispatch({ type: "UPDATE_TICKET", payload: data.ticket });
            }

            if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
                dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
            }
        };

        // Listen to socket event
        const events = []
        selectedQueueIds.forEach((queueId) => events.push(`queue:${queueId}:status:${status}`))
        if (!limitNoQueue) events.push(`queue:no-queue:status:${status}`)

        // Start listeners
        events.forEach((event) => socket.on(event, handleChatsChange))

        // Clean up
        return () => {
            events.forEach((event) => socket.off(event, handleChatsChange))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGroup, limitNoQueue, selectedQueueIds, socket, status, userTenant])

    useEffect(() => {
        if (typeof updateCount !== "function") return;

        if (limitNoQueue) {
            updateCount(ticketsList.filter(ticket => ticket.queueId !== null).length);
        } else {
            updateCount(ticketsList.length);
        }
    }, [
        limitNoQueue,
        ticketsList,
        updateCount,
    ]);

    const loadMore = () => {
        setPageNumber((prev) => prev + 1);
    };

    const handleScroll = e => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            // e.currentTarget.scrollTop = scrollTop - 100;
            loadMore();
        }
    };

    return (
        <Paper className={classes.ticketsListWrapper} style={style}>
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                onScroll={handleScroll}
            >
                <List style={{ paddingTop: 0 }}>
                    {!loading && userTenant?.queues && ticketsList?.length === 0 ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                {i18n.t("translation.ticketsList.noTickets.title")}
                            </span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("translation.ticketsList.noTickets.message")}
                            </p>
                        </div>
                    ) : (
                        <>
                            {ticketsList?.map(ticket => (
                                <TicketListItem ticket={ticket} key={ticket?.id} />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Paper>
    );
};

export default TicketsList;
